<template>
  <div v-if="error" class="notification is-danger field">
    <button class="delete" @click="error = null"></button>
    {{ error }}
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: String
  }
}
</script>
