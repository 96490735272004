<template>
  <layout page="profile">
    <page-title icon="lni lni-user" :breadcrumb="breadcrumb">Perfil</page-title>
    <div class="card">
      <div class="card-content">
        <div class="form">
          <error :error="error" />
          <div class="field field-avatar">
            <figure class="image is-128x128">
              <avatar class="form-avatar" :username="user.name"></avatar>
            </figure>
          </div>
          <div class="field">
            <label class="label" for="name">Nome<asterisk /></label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="name" id="name" placeholder="Seu nome" ref="name" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="email">Email<asterisk /></label>
            <div class="control">
              <input v-model="email" class="input" type="text" name="email" id="email" placeholder="Seu email" ref="email" required />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label" for="password">Senha atual<asterisk /></label>
                <div class="control">
                  <input v-model="password" class="input" type="password" name="password" id="password" placeholder="Sua senha atual" ref="password" required />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label" for="newPassword">Nova senha<asterisk /></label>
                <div class="control">
                  <input v-model="newPassword" class="input" type="newPassword" name="newPassword" id="newPassword" placeholder="Sua nova senha" ref="newPassword" required />
                </div>
              </div>
            </div>
          </div>
          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send" :disabled="enviando">Salvar Perfil</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" to="/admin/turmas">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { mapState } from 'vuex'
import userRules from '@/config/userRules'
import { PAINEL, PROFILE } from '@/config/breadcrumb/painel'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Error from '@/components/ui/Error'
import Avatar from 'vue-avatar'
import Asterisk from '@/components/ui/Asterisk'

export default {
  name: 'Profile',
  components: {
    Layout,
    PageTitle,
    Error,
    Avatar,
    Asterisk
  },
  created () {
    this.name = this.user.name
    this.email = this.user.email
  },
  data () {
    return {
      name: '',
      email: '',
      password: '',
      newPassword: '',
      error: null,
      enviando: false
    }
  },
  computed: {
    ...mapState(['user']),
    breadcrumb () {
      return [
        PAINEL,
        { ...PROFILE, isActive: true }
      ]
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.name === '') {
        return this.fieldInvalid('name', 'Nome é obrigátorio')
      }
      this.error = null

      if (this.email === '') {
        return this.fieldInvalid('email', 'Email é obrigátorio')
      }
      this.error = null

      if (this.newPassword !== '' && this.password === '') {
        return this.fieldInvalid('password', 'Para mudar a senha é necessário digitar a senha atual')
      }
      this.error = null

      if (this.user.rule === userRules.ADMIN) {
      } else if (this.user.rule === userRules.TEACHER) {
      } else if (this.user.rule === userRules.STUDENT) {
      } else if (this.user.rule === userRules.RESPONSIBLE) {
      }
    },
    fieldInvalid (ref, message) {
      this.error = message
      this.$refs[ref].focus()
      this.enviando = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.profile {
  .card {
    .form {
      .field-avatar {
        .image {
          margin: 0 auto;
          .form-avatar {
            width: 128px !important;
            height: 128px !important;
            background-color: $primary-color !important;
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
