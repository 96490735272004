const PAINEL = {
  path: '/p',
  text: 'Painel'
}

const PROFILE = {
  path: '/p/perfil',
  text: 'Perfil'
}

export {
  PAINEL,
  PROFILE
}
